
/* eslint-disable @typescript-eslint/camelcase */

import { Component, Vue, Watch } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { DocSearchStats } from "@/models/dashboard/doc.search.stats.model";
import moment from "moment";
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    ValidationObserver
  },
})
export default class JnDashboardDocAccessStats extends Vue {
  headers = [
    {
      text: "Data di ricerca",
      align: "start",
      sortable: true,
      value: "search_date",
    },
    { text: "Keywords", value: "search_keywords", sortable: true },
    { text: "Ambito", value: "search_scope", sortable: true },
    { text: "Numero di risultati", value: "search_result_count", sortable: true },
  ];

  loading = true;
  docSearchStats: DocSearchStats[] = [];

  options = {
    sortDesc: true,
    sortBy: ["search_date"],
    page: 1,
    itemsPerPage: 20,
  };
  sortBy = "search_date";
  sortDesc = true;
  page = 1;
  itemsPerPage = 20;
  docSearchStatsLength = 0;

  optDataDa = ""
  optDataDaFormatted = ""
  dataDa = false;
  optDataAFormatted = "";
  dataA = false;
  optDataA = "";

  isFormValid = false
  validationMessage = ""

  async beforeMount() {
    await this.loadFromDatabase();
    await JnService.countDocSearchStats();
  }

  @Watch("sortBy")
  @Watch("sortDesc")
  @Watch("itemsPerPage")
  @Watch("page")
  async updateSort() {
    await this.loadFromDatabase();
  }

  async loadFromDatabase() {
    this.loading = true;
    const allDocSearchStats = await JnService.getDocSearchStats(
      (this.page - 1) * this.itemsPerPage,
      this.itemsPerPage,
      this.sortBy,
      this.sortDesc ? "DESC" : "ASC",
      this.optDataDaFormatted == null ? "" : this.optDataDaFormatted,
      this.optDataAFormatted == null ? "" : this.optDataAFormatted
    );
    this.docSearchStatsLength = Number((await JnService.countDocSearchStats()).data.count);
    this.docSearchStats = [];
    for (const d of allDocSearchStats.data) {
      this.docSearchStats.push(
        new DocSearchStats({
          id: d.id,
          searchDate: d.search_date,
          searchKeywords:  d.search_keywords,
          searchScope: d.search_scope,
          searchResultCount: d.search_result_count,
        })
      );
    }
    this.loading = false;
  }

  parseDate(date: string) {
    return date ? moment(date, "DD-MM-YYYY").format("YYYY-MM-DD") : "";
  }

  clearDataDa() {
    this.optDataDa = ''
    this.checkFormValidation()
  }
  clearDataA() {
    this.optDataA = ''
    this.checkFormValidation()
  }
  saveDataDa(date: any) {
    this.optDataDaFormatted = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    this.dataDa = false;
    this.checkFormValidation()
  }

  saveDataA(date: any) {
    this.optDataAFormatted = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    this.dataA = false;
    this.checkFormValidation()
  }

  checkFormValidation() {
    if (this.optDataDa && this.optDataA) {
      if (moment(this.optDataDa).isBefore(moment(this.optDataA))) {
        this.isFormValid = true
      }
      else {
        this.isFormValid = false
        this.validationMessage = "La data di inizio deve essere precedente alla data di fine"
      }      
    }
    else {
      this.isFormValid = true
      this.validationMessage = ""
    }
  }

  formatDate(value: any) {
      return moment(value).format("DD-MM-YYYY HH:mm:ss")
  }

}
