
import { Component, Vue } from 'vue-property-decorator';
import { JnService } from '@/services/jn.service';
import { ValidationObserver } from 'vee-validate';
import {
  FcmTopicsPlatform,
  FcmTopicsAreeTematiche,
} from '@/models/enums.model';

@Component({
  components: { ValidationObserver },
})
export default class JnNotifications extends Vue {
  title = '';
  body = '';
  url = '';

  alert = false;
  alertMessage = '';
  alertTimeout = 6000;

  platforms = [
    { label: 'Portale', value: FcmTopicsPlatform.PORTAL },
    { label: 'App', value: FcmTopicsPlatform.APP },
  ];
  areeTematiche = [
    {
      label: 'Generale',
      value: FcmTopicsAreeTematiche.GENERALE,
    },
    {
      label: 'Diritto Societario',
      value: FcmTopicsAreeTematiche.DIRITTO_SOCIETARIO,
    },
    {
      label: 'Diritto Fallimentare',
      value: FcmTopicsAreeTematiche.DIRITTO_FALLIMENTARE,
    },
    {
      label: 'Diritto Tributario',
      value: FcmTopicsAreeTematiche.DIRITTO_TRIBUTARIO,
    },
    {
      label: 'Diritto del Lavoro',
      value: FcmTopicsAreeTematiche.DIRITTO_DEL_LAVORO,
    },
    {
      label: 'Assicurazioni e Risarcimento Danni',
      value: FcmTopicsAreeTematiche.ASSICURAZIONI,
    },
    {
      label: 'Diritto di Famiglia e Successioni',
      value: FcmTopicsAreeTematiche.DIRITTO_DI_FAMIGLIA,
    },
    {
      label: 'Protezione dei dati personali',
      value: FcmTopicsAreeTematiche.PRIVACY,
    },
    {
      label: 'Mercati immobiliari',
      value: FcmTopicsAreeTematiche.MERCATI_IMMOBILIARI,
    },
    {
      label: 'Contrattualistica di diritto privato',
      value: FcmTopicsAreeTematiche.CONTRATTUALISTICA_DIRITTO_PRIVATO,
    },
    {
      label: 'Bancario e mercati finanziari',
      value: FcmTopicsAreeTematiche.BANCARIO_MERCATI_FINANZIARI,
    },
    {
      label: 'Diritto Processuale Telematico',
      value: FcmTopicsAreeTematiche.PROCESSO_TELEMATICO,
    },
    {
      label: 'Diritto Penale',
      value: FcmTopicsAreeTematiche.DIRITTO_PENALE,
    },
    {
      label: 'Diritto Amministrativo',
      value: FcmTopicsAreeTematiche.DIRITTO_AMMINISTRATIVO,
    },
    {
      label: 'Nuove Tecnologie',
      value: FcmTopicsAreeTematiche.DIRITTO_NUOVE_TECNOLOGIE,
    },
  ];

  platform = FcmTopicsPlatform.APP;
  areaTematica = FcmTopicsAreeTematiche.GENERALE;

  async sendNotification() {
    try {
      /**
       * Invia messaggi a client che sono sottoscritti sia al topic corrispondente
       * alla piattaforma selezionata sia al topic corrispondente all'area tematica
       * selezionata
       */
      const topicCondition =
        "'" +
        this.platform +
        "' in topics && '" +
        this.areaTematica +
        "' in topics";
      await JnService.fcmSendNotification(
        this.title,
        this.body,
        this.url,
        topicCondition
      );
      this.alertMessage = 'Notifica inviata con successo!';
      this.alert = true;
    } catch (err) {
      this.alertMessage = "Errore nell'invio della notifica: " + err;
      this.alert = true;
    }
  }
}
