
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { JnService } from '@/services/jn.service';
import { Dossier } from '@/models/dossier.model';
import EventBus from '@/services/event.bus';
import { DossierDocument } from '@/models/dossier.document.model';
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';

@Component({
  components: { EditorContent },
})
export default class JnEditor extends Vue {
  editor = new Editor({
    extensions: [StarterKit, Underline, Highlight],
    autofocus: true,
    editable: true,
    injectCSS: false,
    content: null,
  });

  minHeight = 400;
  maxHeight = 400;

  get cssProps() {
    return {
      '--editor-min-height': this.minHeight + 'px',
      '--editor-max-height': this.maxHeight + 'px',
    };
  }

  reduceEditor() {
    this.minHeight = this.minHeight - 10;
    this.maxHeight = this.maxHeight - 10;
  }
  extendEditor() {
    this.minHeight = this.minHeight + 10;
    this.maxHeight = this.maxHeight + 10;
  }
  async beforeMount() {
    const editorText = this.$store.getters.dossierDocument;
    if (editorText) {
      this.editor = new Editor({
        extensions: [StarterKit],
        autofocus: true,
        editable: true,
        injectCSS: false,
        content: editorText,
      });
    }
    EventBus.$on('updateEditor', async (content: string) => {
      if (this.editor) {
        this.editor.destroy();
      }
      this.editor = new Editor({
        extensions: [StarterKit],
        autofocus: true,
        editable: true,
        injectCSS: false,
        content: content,
      });
    });
  }

  save() {
    EventBus.$emit('editorSaved', this.editor.getHTML());
  }
}
