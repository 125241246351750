/* eslint-disable @typescript-eslint/camelcase */

interface UsersProps {
  id: string;
  username: string;
  active: boolean;
  changeRequired: boolean;
  lastAccess: Date;
}

export class Users  {

  private props: UsersProps

  constructor(props: UsersProps) {
    this.props = props
  }

  // GETTERs and SETTERs
  get id(): string {
    return this.props.id
  }

  get username(): string {
    return this.props.username
  }

  get active(): boolean {
    return this.props.active
  }

  set active(active: boolean) {
    this.props.active = active
  }

  get change_required(): boolean {
    return this.props.changeRequired
  }

  get last_access(): Date {
    return this.props.lastAccess
  }

}
