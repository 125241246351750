
/* eslint-disable @typescript-eslint/camelcase */

import { Component, Vue, Watch } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { DocAccessStats } from "@/models/dashboard/doc.access.stats.model";
import moment from "moment";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    ValidationObserver,
  },
})
export default class JnDashboardDocAccessStats extends Vue {
  headers = [
    {
      text: "Utente",
      align: "start",
      sortable: true,
      value: "username",
    },
    { text: "ID Documento", value: "doc_id", sortable: true },
    { text: "URL Documento", value: "doc_url", sortable: false },
    { text: "Data Accesso", value: "access_date", sortable: true },
  ];

  loading = true;
  docAccessStats: DocAccessStats[] = [];

  options = {
    sortDesc: true,
    sortBy: ["access_date"],
    page: 1,
    itemsPerPage: 20,
  };
  sortBy = "access_date";
  sortDesc = true;
  page = 1;
  itemsPerPage = 20;
  docAccessStatsLength = 0;

  optDataDa = "";
  optDataDaFormatted = "";
  dataDa = false;
  optDataAFormatted = "";
  dataA = false;
  optDataA = "";

  isFormValid = false;
  validationMessage = "";

  async beforeMount() {
    await this.loadFromDatabase();
    await JnService.countDocAccessStats();
  }

  @Watch("sortBy")
  @Watch("sortDesc")
  @Watch("itemsPerPage")
  @Watch("page")
  async updateSort() {
    await this.loadFromDatabase();
  }

  async loadFromDatabase() {
    this.loading = true;
    const allDocAccessStats = await JnService.getDocAccessStats(
      (this.page - 1) * this.itemsPerPage,
      this.itemsPerPage,
      this.sortBy,
      this.sortDesc ? "DESC" : "ASC",
      this.optDataDaFormatted == null ? "" : this.optDataDaFormatted,
      this.optDataAFormatted == null ? "" : this.optDataAFormatted
    );
    this.docAccessStatsLength = Number((await JnService.countDocAccessStats()).data.count);
    this.docAccessStats = [];
    for (const d of allDocAccessStats.data) {
      this.docAccessStats.push(
        new DocAccessStats({
          id: d.id,
          username: d.username,
          accessDate: d.access_date,
          docId: d.doc_id,
          docUrl: d.doc_url,
        })
      );
    }
    this.loading = false;
  }

  parseDate(date: string) {
    return date ? moment(date, "DD-MM-YYYY").format("YYYY-MM-DD") : "";
  }

  clearDataDa() {
    this.optDataDa = "";
    this.checkFormValidation();
  }
  clearDataA() {
    this.optDataA = "";
    this.checkFormValidation();
  }
  saveDataDa(date: any) {
    this.optDataDaFormatted = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    this.dataDa = false;
    this.checkFormValidation();
  }

  saveDataA(date: any) {
    this.optDataAFormatted = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    this.dataA = false;
    this.checkFormValidation();
  }

  checkFormValidation() {
    if (this.optDataDa && this.optDataA) {
      if (moment(this.optDataDa).isBefore(moment(this.optDataA))) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
        this.validationMessage =
          "La data di inizio deve essere precedente alla data di fine";
      }
    } else {
      this.isFormValid = true;
      this.validationMessage = "";
    }
  }

  formatDate(value: any) {
    return moment(value).format("DD-MM-YYYY HH:mm:ss");
  }
}
