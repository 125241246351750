/* eslint-disable @typescript-eslint/camelcase */

interface DocAccessStatsProps {
  id: string;
  username: string;
  accessDate: Date;
  docId: number;
  docUrl: string;
}

export class DocAccessStats {

  private props: DocAccessStatsProps

  constructor(props: DocAccessStatsProps) {
    this.props = props
  }

  // GETTERs and SETTERs
  get id(): string {
    return this.props.id
  }

  get username(): string {
    return this.props.username
  }

  get access_date(): Date {
    return this.props.accessDate
  }

  get doc_id(): number {
    return this.props.docId
  }

  get doc_url(): string {
    return this.props.docUrl
  }

}
