
import { Component, Vue } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { CrmInfo } from "@/models/crm/crmInfo.model";
import JnProgress from "@/views/common/Progress.vue";
import moment from "moment";

@Component({
  components: { JnProgress },
})
export default class JnSubscription extends Vue {
  readonly = true;
  loading = true;

  crmInfo?: CrmInfo;
  previousPurchaseOrder = null;

  async beforeMount() {
    this.crmInfo = this.$store.getters.crmInfo;

    if (!this.crmInfo?.purchase) {
      const lastPurchaseOrder = await JnService.getLastPurchaseOrder(
        this.crmInfo?.user.id!
      );
      const now = moment();
      if (
        lastPurchaseOrder &&
        now.isAfter(moment(lastPurchaseOrder.scadenza, "YYYY-MM-DD"))
      ) {
        this.previousPurchaseOrder = lastPurchaseOrder;
      }
    }
    this.loading = false;
  }
}
