
import { Component, Vue } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import {
  FcmTopicsPlatform,
  FcmTopicsAreeTematiche,
} from "@/models/enums.model";
import { getToken } from "firebase/messaging";
import getMessaging from "@/firebase";

@Component({
  components: {},
})
export default class JnNotificationsPreferences extends Vue {
  title = "";
  body = "";
  url = "";

  alert = false;
  alertMessage = "";
  alertTimeout = 6000;

  generaleEnabled = false;
  dirittoSocietarioEnabled = false;
  dirittoFallimentareEnabled = false;
  dirittoTributarioEnabled = false;
  dirittoPenaleEnabled = false;
  dirittoDelLavoroEnabled = false;
  assicurazioniRisarcimentoDanni = false;
  dirittoFamigliaSuccessioni = false;
  protezioneDatiPersonali = false;
  mercatiImmobiliari = false;
  contrattualisticaDirittoPrivato = false;
  bancarioMercatiFinanziari = false;
  processoTelematico = false;
  dirittoAmministrativo = false;
  nuoveTecnologie = false;

  FcmTopicsAreeTematiche = FcmTopicsAreeTematiche;

  fcmAll = [
    {
      name: "Ambito generale",
      topic: FcmTopicsAreeTematiche.GENERALE,
      status: this.generaleEnabled,
    },
    {
      name: "Diritto Societario",
      topic: FcmTopicsAreeTematiche.DIRITTO_SOCIETARIO,
      status: this.dirittoSocietarioEnabled,
    },
    {
      name: "Diritto Fallimentare",
      topic: FcmTopicsAreeTematiche.DIRITTO_FALLIMENTARE,
      status: this.dirittoFallimentareEnabled,
    },
    {
      name: "Diritto Tributario",
      topic: FcmTopicsAreeTematiche.DIRITTO_TRIBUTARIO,
      status: this.dirittoTributarioEnabled,
    },
    {
      name: "Diritto Penale",
      topic: FcmTopicsAreeTematiche.DIRITTO_PENALE,
      status: this.dirittoPenaleEnabled,
    },
    {
      name: "Diritto del Lavoro",
      topic: FcmTopicsAreeTematiche.DIRITTO_DEL_LAVORO,
      status: this.dirittoDelLavoroEnabled,
    },
    {
      name: "Assicurazioni e Risarcimento Danni",
      topic: FcmTopicsAreeTematiche.ASSICURAZIONI,
      status: this.assicurazioniRisarcimentoDanni,
    },
    {
      name: "Diritto di Famiglia e Successioni",
      topic: FcmTopicsAreeTematiche.DIRITTO_DI_FAMIGLIA,
      status: this.dirittoFamigliaSuccessioni,
    },
    {
      name: "Protezione dei dati personali",
      topic: FcmTopicsAreeTematiche.PRIVACY,
      status: this.protezioneDatiPersonali,
    },
    {
      name: "Mercati immobiliari",
      topic: FcmTopicsAreeTematiche.MERCATI_IMMOBILIARI,
      status: this.mercatiImmobiliari,
    },
    {
      name: "Contrattualistica di diritto privato",
      topic: FcmTopicsAreeTematiche.CONTRATTUALISTICA_DIRITTO_PRIVATO,
      status: this.contrattualisticaDirittoPrivato,
    },
    {
      name: "Bancario e mercati finanziari",
      topic: FcmTopicsAreeTematiche.BANCARIO_MERCATI_FINANZIARI,
      status: this.bancarioMercatiFinanziari,
    },
    {
      name: "Diritto Processuale Telematico",
      topic: FcmTopicsAreeTematiche.PROCESSO_TELEMATICO,
      status: this.processoTelematico,
    },
    {
      name: "Diritto Amministrativo",
      topic: FcmTopicsAreeTematiche.DIRITTO_AMMINISTRATIVO,
      status: this.dirittoAmministrativo,
    },
    {
      name: "Nuove Tecnologie",
      topic: FcmTopicsAreeTematiche.DIRITTO_NUOVE_TECNOLOGIE,
      status: this.nuoveTecnologie,
    },
  ];

  async setTopic(event: any, topic: string, cookie: string) {
    const messaging = getMessaging();
    const token = await getToken(messaging, {
      vapidKey: process.env.VUE_APP_FCM_VAPID_KEY,
    });
    if (event) {
      console.log(
        'JnNotitificationPreferences.setTopic: subscribing to topic "' +
        topic +
        '" with token "' +
        token +
        '"'
      );
      this.$cookies.set("fcm-topic-" + topic, true, {
        expires: new Date("9999-12-31T23:59:59.999Z"),
      });
      await JnService.fcmRegisterTopic(token, FcmTopicsPlatform.PORTAL);
      await JnService.fcmRegisterTopic(token, topic);
    } else {
      console.log(
        'JnNotitificationPreferences.setTopic: unsubscribing from topic "' +
        topic +
        '" with token "' +
        token +
        '"'
      );
      this.$cookies.set("fcm-topic-" + topic, false, {
        expires: new Date("9999-12-31T23:59:59.999Z"),
      });
      await JnService.fcmUnregisterTopic(token, topic);
    }
  }

  beforeMount() {
    for (const fcmTopic of this.fcmAll) {
      console.log(
        "'JnNotificationsPreferences.beforeMount: getting cookie 'fcs-topic-" +
        fcmTopic.topic +
        "'"
      );
      console.log(
        "JnNotificationsPreferences.beforeMount: " +
        this.$cookies.get("fcm-topic-" + fcmTopic.topic)
      );
      if (this.$cookies.get("fcm-topic-" + fcmTopic.topic) === "true") {
        fcmTopic.status = true;
      } else {
        fcmTopic.status = false;
        this.$cookies.set("fcm-topic-" + fcmTopic.topic, false, {
          expires: new Date("9999-12-31T23:59:59.999Z"),
        });
      }
    }
  }
}
