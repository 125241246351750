/* eslint-disable @typescript-eslint/camelcase */

interface DocSearchStatsProps {
  id: string;
  searchKeywords: string;
  searchDate: Date;
  searchScope: string;
  searchResultCount: number;
}

export class DocSearchStats {

  private props: DocSearchStatsProps

  constructor(props: DocSearchStatsProps) {
    this.props = props
  }

  // GETTERs and SETTERs
  get id(): string {
    return this.props.id
  }

  get search_keywords(): string {
    return this.props.searchKeywords
  }

  get search_date(): Date {
    return this.props.searchDate
  }

  get search_scope(): string {
    return this.props.searchScope
  }

  get search_result_count(): number {
    return this.props.searchResultCount
  }

}
